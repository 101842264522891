import { EmptyState } from "@shopify/polaris";
import { adminConfigRoutePrefix, adminRoutePrefix } from "@smartrr/shared/constants";
import { ISmartrrBundleConfig } from "@smartrr/shared/entities/SellingPlanGroup";
import { AppReqResponse, Req } from "@smartrr/shared/req";
import { injectLiquid } from "@smartrr/shared/shopifyLiquid/liquidOperations";
import { smartrrBundleRenderInjection, smartrrBundleSnippets } from "@smartrr/shared/shopifyLiquid/templates";
import { getShopifyAsset } from "@smartrr/shared/shopifyRest/asset";
import {
  IShopifyGetThemesResponse,
  ISmartrrShopTheme,
  getShopifyThemes,
} from "@smartrr/shared/shopifyRest/theme";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useRestClient } from "@vendor-app/app/AdminRoute/components/auth/RestProviderWrapper";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { AdminBundleRouteWithData } from "./AdminBundleRouteWithData";
import { ApplyToThemeModal } from "../../AdminThemingRoute/components/ApplyToThemeModal";
import { Spinner } from "../../components/elements/Spinner/index";
import { emptyBundle } from "../components/constants";


export function AdminBundleConfigRoute({ bundleId }: { bundleId: string }): JSX.Element {
  const { addToast } = useToast();
  const [bundle, setBundle] = useState<ISmartrrBundleConfig>();
  const [isLoading, setIsLoading] = useState(true);
  const isCreatingNew = useMemo(() => bundleId === "create", [bundleId, bundle]);
  const [showInjectionModal, setShowInjectionModal] = useState(false);
  const restClient = useRestClient();
  const [shopifyThemes, setShopifyThemes] = useState<IShopifyGetThemesResponse["themes"]>([]);
  const [mainThemeLoading, setMainThemeLoading] = useState(true);
  const [mainThemeId, setMainThemeId] = useState<number>();

  const getMainThemeId = useCallback(async () => {
    const res = await getShopifyThemes(restClient);

    if (res.type === "success") {
      setShopifyThemes(res.body.themes || []);
    }

    if (res.type === "error" || !res.body.themes?.find(t => t.role === "main")) {
      addToast("Error getting main theme id");
      setMainThemeLoading(false);
      return;
    }

    const mainTheme = res.body.themes.find(t => t.role === "main")!;
    setMainThemeId(mainTheme.id);
    setMainThemeLoading(false);
  }, []);

  useEffect(() => {
    getMainThemeId();
  }, []);

  //

  const fetchBundle = async (id: string) => {
    setIsLoading(true);
    const bundleResponse = await typedFrontendVendorApi.getReq(`/bundle-config/${id}`);
    if (bundleResponse.type !== "success") {
      addToast("There was an error fetching the bundle");
      setIsLoading(false);
      return;
    }

    setBundle(bundleResponse.body);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isCreatingNew) {
      setBundle(emptyBundle);
      setIsLoading(false);
    } else if (bundleId) {
      fetchBundle(bundleId);
    }
  }, []);

  const onSave = useCallback(
    async (newBundle: ISmartrrBundleConfig) => {
      if (isCreatingNew) {
        const createResponse = await typedFrontendVendorApi.postReq("/bundle-config", {
          reqBody: {
            ...newBundle,
          },
        });
        if (createResponse.type !== "success") {
          addToast("There was an error creating the bundle");
          return;
        }
        setBundle({ ...newBundle });
        addToast("Bundle created successfully");
        navigateWithShopInQuery(`${adminConfigRoutePrefix}/bundles/${createResponse.body?.id}`);
        setShowInjectionModal(true);
      } else {
        const updateResponse = await typedFrontendVendorApi.putReq(`/bundle-config/${bundleId}`, {
          reqBody: { ...newBundle },
        });
        if (updateResponse.type !== "success") {
          addToast("There was an error updating the bundle");
          return;
        }
        setBundle({ ...newBundle });
        addToast("Bundle updated successfully");
        setShowInjectionModal(true);
      }
    },
    [isCreatingNew]
  );

  const onDiscard = useCallback(() => {
    if (isCreatingNew) {
      navigateWithShopInQuery(`${adminRoutePrefix}/configure/bundles`);
    }
  }, [isCreatingNew]);

  const closeModal = useCallback(() => setShowInjectionModal(false), []);

  async function getAsset(restClient: Req, themeId: number, assetId: string) {
    const res = await getShopifyAsset(themeId, assetId, restClient);
    if (res.type === "error" || res.body.asset.value === undefined) {
      const error = `Couldn't get asset ${assetId}`;
      throw new Error(error);
    }
    return res.body.asset.value;
  }

  async function updateAsset(themeId: number, assetId: string, value: string) {
    return typedFrontendVendorApi.putReq("/theme/:themeId/asset", {
      params: {
        themeId: `${themeId}`,
      },
      reqBody: {
        key: assetId,
        value,
      },
    });
  }

  const onInjectScript = async (theme: ISmartrrShopTheme) => {
    const injectionRes = await typedFrontendVendorApi.putReq(
      `/bundle-config/${bundleId}/theme/${(theme && theme.id) || mainThemeId}`
    );

    const bundleSnippetNames = smartrrBundleSnippets.map(snippet => snippet.name);
    const responses = await [...smartrrBundleSnippets, ...smartrrBundleRenderInjection]
      .map(async ({ name, snippet, replacePosition, toReplace, toRemoveBefore }) => {
        let assetValue = "";
        let hasBeenInjected = false;
        try {
          assetValue = await getAsset(restClient, (theme && theme.id) || mainThemeId!, name);

          // check if snippet has already been injected. If reached here, then getAsset was successful
          if (bundleSnippetNames.includes(name)) {
            hasBeenInjected = true;
          }
        } catch {
          // if getAsset throws assume asset doesn't exist and needs to be created
        }
        const newValue = hasBeenInjected
          ? assetValue
          : injectLiquid(name, assetValue, snippet, toReplace, replacePosition, toRemoveBefore);
        if (newValue === undefined) {
          const error = `Error updating asset ${name}`;
          addToast(error);
          return;
        }
        return updateAsset((theme && theme.id) || mainThemeId!, name, newValue);
      })
      .reduce(
        async (
          accum: Promise<(AppReqResponse<any> | undefined)[]>,
          curr: Promise<AppReqResponse<any> | undefined> | undefined
        ) => {
          const arr = await accum;
          return arr.concat(await curr);
        },
        Promise.resolve([])
      );

    const errors = responses.filter(res => !res || res.type === "error");
    if (injectionRes.type !== "success" || errors.length > 0) {
      addToast("Bundle injection failed");
    } else {
      closeModal();
      addToast("Bundle injection successful");
    }
  };

  if (isLoading || mainThemeLoading) {
    return <Spinner />;
  }

  if (!isLoading && !bundle && !isCreatingNew) {
    return (
      <EmptyState
        image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        action={{
          content: "Manage bundles",
          onAction: () => navigateWithShopInQuery(`${adminRoutePrefix}/configure/bundles`),
        }}
      >
        <p>Bundle not found</p>
      </EmptyState>
    );
  }
  return (
    <div>
      <AdminBundleRouteWithData
        bundle={bundle!}
        onSave={onSave}
        onDiscard={onDiscard}
        isCreatingNew={isCreatingNew}
      />
      <ApplyToThemeModal
        open={showInjectionModal}
        themes={shopifyThemes}
        onClose={closeModal}
        onSubmit={onInjectScript}
      />
    </div>
  );
}
