import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

import {
  GetCollectionByHandle,
  GetCollectionByHandleQuery,
  GetCollectionByHandleQueryVariables,
  GetProductCollections,
  GetProductCollectionsQuery,
  GetProductCollectionsQueryVariables,
  GetShopCollections,
  GetShopCollectionsQuery,
  GetShopCollectionsQueryVariables,
} from "./api";

import { apolloQuery, apolloShopifyQuery } from ".";

export function queryShopifyPurchasableCollections(
  client: ApolloClient<NormalizedCacheObject>,
  productCursor: GetProductCollectionsQueryVariables["productCursor"],
  collectionCursor: GetProductCollectionsQueryVariables["collectionCursor"]
) {
  return apolloQuery<GetProductCollectionsQuery, GetProductCollectionsQueryVariables>(
    {
      query: GetProductCollections,
      variables: { productCursor, collectionCursor },
    },
    client
  );
}

export function queryShopCollections(client: ApolloClient<object>, count: number) {
  return apolloShopifyQuery<GetShopCollectionsQuery, GetShopCollectionsQueryVariables>(
    {
      query: GetShopCollections,
      variables: {
        count,
      },
    },
    client
  );
}

export function queryShopCollectionByHandle(client: ApolloClient<object>, handle: string) {
  return apolloShopifyQuery<GetCollectionByHandleQuery, GetCollectionByHandleQueryVariables>(
    {
      query: GetCollectionByHandle,
      variables: {
        handle,
      },
    },
    client
  );
}
