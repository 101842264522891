import {
  Button,
  FormLayout,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Modal,
  OptionList,
  Select,
  Text,
  TextContainer,
  TextField,
  Thumbnail,
} from "@shopify/polaris";
import { OptionDescriptor } from "@shopify/polaris/build/ts/src/types";
import { VariantMajor } from "@shopify/polaris-icons";
import { DEFAULT_VARIANT_TITLE } from "@smartrr/shared/constants";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import { ISmartrrBundleCollections, ISmartrrBundlePacks } from "@smartrr/shared/entities/SellingPlanGroup";
import { ensureShopifyGid, shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import {
  getVariantImageFromPurchasableMap,
  useVariantToPurchasableMap,
} from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { isVariantDeleted, isVariantInStock } from "@smartrr/shared/utils/variants";
import { cloneDeep, isEmpty } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { FixedSearch, ModalVariantTitle } from "@vendor-app/app/_sharedComponents/BrowseProductsModal/components";

import { emptyPack } from "./constants";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

const IconContainer = styled.div`
  .Polaris-LegacyStack {
    align-items: center;
  }
  .Polaris-Thumbnail {
    height: 2.1rem;
  }
  img {
    border: 1px solid #c9cccf;
    border-radius: 4px;
    height: 30px;
    padding: 1px;
    width: 30px;
  }
`;

interface Props {
  packInput: ISmartrrBundlePacks[];
  bundleProduct: IPurchasable | undefined;
  productCollections: ISmartrrBundleCollections[] | undefined;
  pack: ISmartrrBundlePacks;
  index: number;
  setPackInput: React.Dispatch<React.SetStateAction<ISmartrrBundlePacks[]>>;
  onDeletePack: (index: number) => void;
  updatePacksInput: (index: number, properties: Record<string, any>) => void;
  organization: IOrganization | null;
}

export const Bundle = ({
  bundleProduct,
  productCollections,
  pack,
  index,
  setPackInput,
  onDeletePack,
  packInput,
  updatePacksInput,
  organization,
}: Props) => {
  const [showVariantsModal, setShowVariantsModal] = useState<boolean>(false);
  const [modalSelectedVariantId, setModalSelectedVariantId] = useState<string[]>([]);
  const [filteredVariantsSearchText, setFilteredVariantsSearchText] = useState<string>("");
  const [filteredVariants, setFilteredVariants] = useState<IPurchasableVariant[] | null>([]);

  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const variantToPurchasableMap = useVariantToPurchasableMap(purchasables);

  const variantOptions = useMemo(() => {
    if (filteredVariantsSearchText) {
      return filteredVariants;
    }
    return (bundleProduct?.vnts ?? []).filter(v => isVariantInStock(v) && !isVariantDeleted(v));
  }, [bundleProduct?.vnts, filteredVariants, filteredVariantsSearchText]);

  const [selectedVariantData] =
    bundleProduct?.vnts?.filter(
      v => v.shopifyId === (ensureShopifyGid("ProductVariant", pack.buyWithVariant) || modalSelectedVariantId[0])
    ) ?? [];
  const shopUrl = organization?.myShopifyDomain;
  const variantNumId = selectedVariantData && shopifyGidToNumber(selectedVariantData.shopifyId!);
  const productNumId = bundleProduct && shopifyGidToNumber(bundleProduct?.shopifyId!);

  const closeVariantsModal = useCallback(() => {
    setShowVariantsModal(false);
    setModalSelectedVariantId([]);
  }, []);

  const confirmVariant = () => {
    updatePacksInput(index, { buyWithVariant: shopifyGidToNumber(modalSelectedVariantId[0]).toString() });
    setShowVariantsModal(false);
  };

  return (
    <div>
      <LegacyCard
        primaryFooterAction={{
          content: "Add another variant",
          onAction: () => setPackInput(pack => pack.concat(cloneDeep(emptyPack))),

          plain: true,
        }}
        secondaryFooterActions={
          packInput.length > 1
            ? [
                {
                  content: "Delete variant",
                  onAction: () => onDeletePack(index),
                  plain: true,
                  destructive: true,
                },
              ]
            : []
        }
      >
        <LegacyCard.Section>
          <Text variant="headingMd" as="h2">
            {pack.name || `Variant #${index + 1}`}
          </Text>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <FormLayout>
            <LegacyStack distribution="equalSpacing">
              <div>
                <p>Product variant</p>
                <Text variant="bodyMd" as="span" color="subdued">
                  Link the associated variant from the bundle product
                </Text>
              </div>
              <Button onClick={() => setShowVariantsModal(true)}>Browse</Button>
            </LegacyStack>

            <IconContainer>
              <LegacyStack alignment="center" spacing="tight">
                <Icon source={VariantMajor} color="base" />
                {packInput[index].buyWithVariant === "" ? (
                  <Text variant="bodyMd" as="span" color="subdued">
                    {"(None selected)"}
                  </Text>
                ) : (
                  <LegacyStack spacing="tight">
                    <img
                      src={getVariantImageFromPurchasableMap(variantToPurchasableMap, selectedVariantData) || ""}
                      alt={"product Image"}
                    />
                    <Link
                      external={!!selectedVariantData}
                      url={`https://${shopUrl}/admin/products/${productNumId}/variants/${variantNumId}`}
                    >
                      {(!!selectedVariantData && selectedVariantData.purchasableVariantName) || ""}
                    </Link>
                  </LegacyStack>
                )}{" "}
              </LegacyStack>
            </IconContainer>
            <TextField
              autoComplete="off"
              label={
                <Text variant="headingXs" as="h3">
                  VARIANT NAME
                </Text>
              }
              helpText="Label of bundle variant, typically size or number of items"
              value={pack.name}
              onChange={name => updatePacksInput(index, { name })}
              placeholder="Example: 4 pack"
            />
            <TextField
              autoComplete="off"
              label={
                <Text variant="headingXs" as="h3">
                  VARIANT BADGE
                </Text>
              }
              helpText="Optional—used to call emphasis to a bundle variant"
              value={pack.alert}
              onChange={badge => updatePacksInput(index, { alert: badge })}
              placeholder="Most Popular"
            />
            <TextField
              autoComplete="off"
              label={
                <Text variant="headingXs" as="h3">
                  VARIANT DESCRIPTION
                </Text>
              }
              helpText="Optional—max 75 characters"
              value={pack.description}
              onChange={description => updatePacksInput(index, { description })}
              placeholder=""
              maxLength={75}
              showCharacterCount
            />
          </FormLayout>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <LegacyStack vertical>
            <Select
              label={
                <Text variant="headingXs" as="h3">
                  ASSOCIATED SHOPIFY COLLECTION
                </Text>
              }
              options={productCollections?.map(collection => ({
                label: collection.title,
                value: collection.handle,
              }))}
              onChange={collectionString => updatePacksInput(index, { collectionString })}
              value={pack.collectionString}
              placeholder="None Selected"
            />
            <Text variant="bodyMd" as="span" color="subdued">
              Used to reference the possible contents of the bundle variant. For instructions on how to create a
              collection in Shopify{" "}
              <Link
                external
                url="https://help.smartrr.com/docs/support/admin-portal/what-are-bundles#how-to-creating-a-bundle-in-shopify"
              >
                click here.
              </Link>
            </Text>
          </LegacyStack>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <LegacyStack vertical>
            <Text variant="headingXs" as="h3">
              NUMBER OF ITEMS IN BUNDLE
            </Text>
            <TextField
              label=""
              type="number"
              value={`${pack.minProducts}`}
              onChange={num => updatePacksInput(index, { minProducts: +num, maxProducts: +num })}
              autoComplete="off"
              min={0}
            />
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>

      <Modal
        limitHeight
        title={
          <Text variant="headingLg" as="p">
            Browse variants
          </Text>
        }
        open={showVariantsModal}
        onClose={closeVariantsModal}
        primaryAction={{
          content: "Confirm",
          onAction: confirmVariant,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: closeVariantsModal,
          },
        ]}
      >
        <FixedSearch>
          <Modal.Section>
            <TextField
              autoComplete="off"
              label=""
              placeholder="Search variants"
              value={filteredVariantsSearchText}
              onChange={text => {
                setFilteredVariantsSearchText(text);
                setFilteredVariants(() => {
                  return variantOptions
                    ? variantOptions.filter(
                        v =>
                          v.purchasableVariantName &&
                          v.purchasableVariantName.toLocaleLowerCase().includes(text.toLocaleLowerCase()) &&
                          !v.isDraftOrArchived &&
                          v.isActiveInShopify
                      )
                    : null;
                });
              }}
            />
          </Modal.Section>
          <Modal.Section>
            {isEmpty(variantOptions) ? (
              <TextContainer>
                <p>No product selected</p>
              </TextContainer>
            ) : (
              <OptionList
                onChange={sel => {
                  setModalSelectedVariantId(sel);
                }}
                selected={pack.buyWithVariant ? [pack.buyWithVariant] : modalSelectedVariantId}
                options={
                  variantOptions?.sort().reduce((acc: OptionDescriptor[], v) => {
                    const imageUrl = getVariantImageFromPurchasableMap(variantToPurchasableMap, v);
                    const variantName = `${bundleProduct?.purchasableName}${
                      v.purchasableVariantName === DEFAULT_VARIANT_TITLE
                        ? " (Default Variant)"
                        : ` - ${v.purchasableVariantName}`
                    }`;
                    const label = (
                      <ModalVariantTitle>
                        {variantName}
                        {v.sku ? (
                          <Text variant="bodyMd" as="span" color="subdued">
                            {v.sku}
                          </Text>
                        ) : null}
                      </ModalVariantTitle>
                    );
                    const result: OptionDescriptor = {
                      label,
                      value: v.shopifyId!,
                      // disable option if variant is in another pack
                      disabled: [...packInput]
                        .map(p => ensureShopifyGid("ProductVariant", p.buyWithVariant))
                        .includes(v.shopifyId!),
                      ...(imageUrl && {
                        media: (
                          <Thumbnail size="small" source={imageUrl} alt={bundleProduct?.purchasableName || ""} />
                        ),
                      }),
                    };
                    acc.push(result);
                    return acc;
                  }, []) ?? []
                }
              />
            )}
          </Modal.Section>
        </FixedSearch>
      </Modal>
    </div>
  );
};
