import { ISmartrrBundleConfig, ISmartrrBundlePacks } from "@smartrr/shared/entities/SellingPlanGroup";

export const emptyBundle: ISmartrrBundleConfig = {
  name: "",
  slug: "",
  searchFilters: [
    {
      name: "default",
      searches: [{ displayTerm: "", searchTag: "" }],
    },
  ],
  bundleProduct: "",
  packs: [
    {
      name: "",
      description: "",
      alert: "",
      collectionString: "",
      minProducts: 1,
      maxProducts: 1,
      variantSelection: "",
      buyWithVariant: "",
      searchFilterString: "default",
    },
  ],
  sellingPlanGroupId: "",
  useLineItems: false,
  hideBundleItems: false,
};

export const emptyPack: ISmartrrBundlePacks = {
  name: "",
  description: "",
  alert: "",
  collectionString: "",
  minProducts: 1,
  maxProducts: 1,
  variantSelection: "",
  buyWithVariant: "",
  searchFilterString: "default",
};
