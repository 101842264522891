import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { AdminBundleConfigRoute } from "@vendor-app/app/AdminRoute/AdminBundlesConfigRoute/AdminBundleConfigRoute";
import { AuthorizedRoute } from "@vendor-app/app/AdminRoute/components/authorization/AuthorizedRoute";

export const Route = createLazyFileRoute("/admin/configure/bundles/$bundleId")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  const { bundleId } = Route.useParams();

  return AuthorizedRoute(FeatureEnum.BUNDLES, <AdminBundleConfigRoute bundleId={bundleId} />);
}
